.comments-list {
    list-style: none;
    padding: 0;
    margin: 5px 0;
}

.comments-list .comment {
    font: 13px/1.4em "Open Sans", sans-serif;
    white-space: pre-line;
    overflow-wrap: break-word;
    display: flex;
}

.comments-list .comment.with-avatar {
    display: flex;
}

.comments-list .comment.with-avatar .comment-description {
    margin-left: 10px;
}

.comments-list .comment .nick {
    font-weight: 700;
    margin-right: 5px;
}

.comments-list .comment-description{
    flex: 1;
    min-width: 1px;
}

.comments-list .comment-date {
    font-size: 11px;
    color: #999999;
    line-height: 1.6em;
}

.comments-list .comment-actions{
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -5px;
}

.comments-list .comment-actions .comment-like-wrap{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.comments-list .comment-actions .comment-like-wrap button{
    position: relative;
    z-index: 1;
    background: transparent;
}


.comments-list .comment-actions .comment-like-wrap .count{
    position: relative;
    top: -10px;
}


@media (min-width: 768px) {
    .comments-list .comment-date {
        font-size: 13px;
    }
}
