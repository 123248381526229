.search-drawer-button {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
}

.search-drawer .ant-drawer-header {
    padding: 18px 24px 19px;
}

.search-drawer .ant-drawer-content-wrapper {
    width: 450px !important;
}

.search-form {
    padding: 0 10px;
}

.search-form .search-info-button {
    border: none;
    padding: 0;
    background: transparent;
    color: rgba(0, 0, 0, 0.45);
    outline: none;
    cursor: pointer;
}

.search-form .ant-radio-group {
    font-size: 20px;
    margin-top: 20px;
}

.search-form .search-type-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 11px;
}

.search-form .ant-radio-group,
.search-form .search-type-wrap .ant-radio-button-wrapper span:not(.ant-radio-button) {
    display: flex;
    align-items: center;
}

.search-form .search-type-wrap .anticon {
    font-size: 28px;
}

.search-form .search-type-wrap .label {
    display: none !important;
    font-size: 14px;
    margin-left: 8px;
}

.search-form .input-bg-color {
    border-radius: 20px;
    overflow: hidden;
}

.search-form .search-field {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.invert-search-color .search-field::before {
    position: absolute;
    content: "";
    top: -10px;
    height: 50px;
    left: 0;
    right: 0;
    background-color: #e4e4e4;
}

.search-form .search-field .clear-search {
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    outline: none;
    padding: 0;
    box-shadow: none;
}

.search-form .search-field .ant-input {
    margin-bottom: 0;
    margin-right: 6px;
    box-shadow: none;
    font-size: 13px;
    outline: 0;
    border: 0;
    min-height: 30px;
    background-color: transparent;
    height: 30px;
    padding: 7px 20px 8px 40px;
}

.invert-search-color .search-field .ant-input {
    background-color: #fff;
}

.search-form .search-field .ant-input-prefix {
    left: 10px;
}

.search-form .search-field .anticon {
    font-size: 24px;
}

.search-form .ant-radio-button-wrapper,
.search-form .ant-radio-button-wrapper:hover,
.search-form .ant-radio-button-wrapper:focus {
    outline: none !important;
    box-shadow: initial !important;
    border: none !important;
    background-color: transparent;
}

.search-form .ant-radio-button-wrapper {
    padding: 0 18px;
}

.search-form .ant-radio-button-wrapper:before {
    display: none;
}

.search-info p {
    margin-top: 10px;
}

.search-info ul {
    padding: 0 0 0 20px;
    margin: 0;
}

.search-drawer .ant-drawer-wrapper-body {
    overflow: hidden !important;
}

.search-drawer .ant-drawer-body {
    padding: 0;
    overflow: auto;
    max-height: calc(100vh - 60px);
}

.search-drawer .search-form {
    padding: 24px 24px 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e9ebee;
}

.search-results * + .search-result-not-found {
    margin-top: 40px;
}

.search-result-list {
    position: relative;
    margin: 0 0 30px;
    padding: 0;
    list-style: none;
}

.search-result-list li + li {
    margin-top: 20px;
}

.search-result-list .search-result-not-found {
    justify-content: center;
}

.search-result-list li {
    display: flex;
    align-items: center;
}

.search-result-list .ant-avatar {
    border: 1px solid #e9ebee;
}

.search-result-list a {
    color: inherit;
}

.search-result-not-found {
    text-align: center;
}

.search-result-list .item-loader{
    width: 100%;
    height: 100px;
}

.search-results .item-loader {
    min-height: 100px;
}

.search-results .collection-card-row .card-data {
    padding: 0;
}

.search-results .avatar-photo {
    margin-right: 11px;
}

.search-results .avatar-data {
    padding: 0;
}

.search-results .card-follower-count,
.search-results .card-post-count {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
}

.search-results .card-info .card-counters > div {
    padding: 0 15px;
    margin-left: -15px;
}

.search-results .card-info .card-counters span {
    margin-left: 3px;
}

.search-results .card-follower-count .anticon {
    font-size: 20px;
}

.search-results .card-post-count .anticon {
    font-size: 22px;
}

.search-results .card-info .card-counters,
.search-results .card-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
}

.search-results .card-info .card-counters {
    margin-top: 0;
}

.search-results .card-follow b {
    font-size: 12px;
}

.search-results .collection-list {
    margin-top: -24px;
}

.search-results .collection-card-row {
    display: block;
    padding: 10px 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: 0;
}

.search-results .ant-avatar {
    border-width: 1px;
    border-style: solid;
}

.search-results .card-list-grid {
    padding: 20px 0 0;
}

.search-sort-and-view {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
}

.search-sort-and-view .right-group{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.search-sort-and-view .right-group > * + * {
    margin-left: 20px;
}


.search-sort-and-view .download-button .anticon{
    font-size: 14px;
    margin-right: 6px;

}

.search-sort .ant-radio-button-wrapper-checked:focus-within,
.search-sort .ant-radio-button-wrapper-checked:focus {
    outline: none;
}

.search-sort .ant-radio-button-wrapper {
    border: none;
    background-color: transparent;
    font-size: 12px;
    font-weight: bold;
    box-shadow: none;
    padding: 0;
}

.search-sort .ant-radio-button-wrapper + .ant-radio-button-wrapper {
    margin-left: 30px;
}

.search-sort .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    border: none;
    box-shadow: none;
}

.search-sort .ant-radio-button-wrapper:not(:first-child)::before {
    display: none !important;
}

@media screen and (max-width: 479px) {
    .search-drawer .ant-drawer-content-wrapper {
        width: 100% !important;
    }
}

@media (max-width: 767px) {

    .search-form .search-field .clear-search {
        height: 30px;
    }

    .search-sort {
        display: flex;
        justify-content: center;
        width: 100%;
        border-bottom: 1px solid#DDDDDD;
        padding-bottom: 5px;
        margin-bottom: 10px;
    }

    .search-form .search-field .ant-input{
        line-height: 30px;
    }

    .search-sort-and-view .download-button{
        margin-bottom: 10px;
    }

    .hashtags-list button.rounded-button,
    .users-list button.rounded-button {
        min-width: 100px;
    }
}

@media (min-width: 768px) {
    .search-form {
        padding: 0;
    }

    .search-result-list li + li {
        margin-top: 20px;
    }

    .search-results .card-follow b {
        font-size: 14px;
    }

    .search-form .search-field .ant-input {
        margin-bottom: 0;
        box-shadow: none;
        font-size: 14px;
        min-height: 40px;
        height: 40px;
        padding: 10px 20px 11px 55px;
    }

    .invert-search-color .search-field .ant-input {
        background-color: transparent;
    }

    .invert-search-color .search-field::before {
        display: none;
    }

    .search-form .search-type-wrap .label {
        display: flex !important;
    }

    .search-form .ant-radio-button-wrapper {
        padding: 0 15px;
    }

    .search-sort-and-view {
        justify-content: space-between;
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    .search-sort-and-view .download-button .anticon{
        font-size: 18px;
        margin-right: 8px;
    }

    .search-sort .ant-radio-button-wrapper {
        font-size: 14px;
    }

    .search-form .search-type-wrap {
        margin-bottom: 40px;
    }

    .search-sort .ant-radio-button-wrapper + .ant-radio-button-wrapper {
        margin-left: 35px;
    }

    .search-form .search-field .ant-input-prefix {
        left: 20px;
    }

    .search-results .avatar-photo {
        margin-right: 20px;
    }

    .search-results .card-follower-count,
    .search-results .card-post-count {
        font-size: 13px;
    }

    .search-results .collection-list {
        margin-top: 0;
    }

    .search-results .card-info .card-counters > div {
        padding: 0 10px;
        margin-left: 0;
    }

    .search-form .search-field .clear-search {
        font-size: 14px;
        margin-left: 20px;
    }
}

@media (min-width: 1024px) {
    .search-results .card-info {
        width: 30%;
    }

    .search-results .collection-card-row {
        display: flex;
        padding: 20px 0;
    }

    .search-results .collection-card-row .card-data {
        flex: 1;
        display: flex;
        min-width: 1px;
        margin-right: 20px;
        max-width: 80%;
        padding: 0;
    }

    .search-results .card-info .card-counters,
    .search-results .card-info {
        margin-top: 0;
    }
}
