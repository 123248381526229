.authorization-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #fff;
}

.authorization-page:before {
    content: "";
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    background: url("../../../assets/login_bg.png") no-repeat scroll center center
    transparent;
    background-size: cover;
    opacity: 0.8;
}

.authorization-page .form-holder {
    font: 14px/1em Open Sans, sans-serif;
    background-color: #fff;
    width: 400px;
    padding: 30px 15px;
    z-index: 1;
    border-radius: 15px;
}

.authorization-page .form-holder h2 {
    font: 16px/1.3em Open Sans, sans-serif;
    text-align: center;
    margin-bottom: 10px;
}

.authorization-page .form-holder form {
    margin: 0 auto;
}

.authorization-page .ant-input {
    font: 12px/1em Open Sans, sans-serif;
    color: #000;
    height: 32px;
    box-shadow: none;
    border-radius: 8px;
    padding: 8px;
}

.authorization-page .ant-form-item {
    margin-bottom: 11px;
}

.authorization-page .ant-form-item-label {
    padding-bottom: 0;
}

.authorization-page .site-label-text-color {
    display: inline-block;
}

.authorization-page .sign-link-wrap .site-label-text-color {
    font-size: 13px;
}

.authorization-page .ant-form-item-control {
    line-height: 1em;
}

.authorization-page .ant-form-item-with-help {
    margin-bottom: 5px;
}

.authorization-page .ant-form-explain {
    font-size: 11px;
    line-height: 1.4em;
    min-height: 15px;
    margin-top: 0;
}

.authorization-page .logo-img {
    text-align: center;
    margin-bottom: 20px;
}

.authorization-page .logo-img img {
    min-width: 120px;
}

.authorization-page .divider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px auto;
    width: 280px;
}

.authorization-page .divider > * {
    flex-grow: 1;
    flex-shrink: 1;
}

.authorization-page .divider > *:nth-of-type(2) {
    padding: 0 20px;
    flex-grow: 0;
    flex-shrink: 0;
}

.authorization-page .sign-link {
    display: block;
}

.authorization-page .sign-link-wrap {
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    margin-top: 20px;
}

.authorization-page .sign-link-wrap span {
    margin-right: 5px;
}

.authorization-page .login-form-link {
    display: inline-block;
    font-size: 11px;
    margin: 0 6px 0;
    padding-bottom: 10px;
}

.authorization-page button.rounded-button {
    font-size: 13px;
    margin: 12px auto 0;
}

.authorization-page .ant-form-item-required::before {
    position: absolute;
    top: 3px;
}

@media (min-width: 768px) {
    .authorization-page {
        background-color: #000;
    }

    .authorization-page:before {
        display: block;
    }

    .authorization-page .form-holder {
        padding: 30px 40px;
    }

    .authorization-page .form-holder h2 {
        margin-bottom: 15px;
    }

    .authorization-page .ant-input {
        font: 14px/1em Open Sans, sans-serif;
        height: 40px;
        padding: 10px 15px;
    }

    .authorization-page .ant-form-item {
        margin-bottom: 15px;
    }

    .authorization-page .ant-form-item:last-child {
        padding-top: 5px;
        margin-bottom: 0;
    }

    .authorization-page .ant-form-explain {
        font-size: 13px;
    }

    .authorization-page .sign-link-wrap {
        font-size: 14px;
    }

    .authorization-page .login-form-link {
        font-size: 13px;
        margin: 0;
        padding-bottom: 0;
    }

    .authorization-page button.rounded-button {
        margin: 0;
    }

    .authorization-page .ant-form-item-required::before {
        position: static;
        top: 0;
    }
}
