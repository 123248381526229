.ant-mentions{
    box-shadow: none;
}

.ant-mentions textarea{
    border-radius: 8px;
    font: 14px/1em Open Sans, sans-serif;
    padding: 10px 15px;
}

.mention-result{
    position: relative !important;
    width: 100%;
    height: 2px;
}

.mention-result .ant-mentions-dropdown{
    max-height: 255px;
    left: 0 !important;
    width: 100%;
    top: initial !important;
    bottom: 0 !important;
    background-color: #FFF !important;
}

.mention-result.after .ant-mentions-dropdown{
    top: 0 !important;
    bottom: initial !important;
}

.mention-result .ant-mentions-dropdown > *{
    width: 100%;
}

.mention-result .card-counters{
    display: flex;
}

.mention-result .card-counters > * + *{
    margin-left: 10px;
    font-size: 12px;
}

.mention-result .author span,
.mention-result .card-counters span{
    margin-left: 3px;
}

.mention-result .ant-mentions-dropdown-menu-item{
    padding: 6px 12px;
}

.mention-result .ant-mentions-dropdown-menu-item-active,
.mention-result .ant-mentions-dropdown-menu-item:hover{
    background: transparent;
}

.mention-result .ant-mentions-dropdown-menu-item-active .avatar:after,
.mention-result .ant-mentions-dropdown-menu-item .avatar:after {
    content: "";
    border-radius: 50%;
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -3px;
}

.mention-result .ant-mentions-dropdown-menu-item-active .avatar:after{
    display: none;
}

.mention-result .ant-mentions-dropdown-menu-item-active .avatar:after,
.mention-result .ant-mentions-dropdown-menu-item:hover .avatar:after{
    width: 6px;
    height: 6px;
}

.mention-result .ant-avatar {
    border-width: 1px;
    border-style: solid;
}

@media (min-width: 768px) {
    .mention-result .ant-mentions-dropdown-menu-item-active .avatar:after{
        display: block;
    }

    .mention-result .ant-mentions-dropdown{
        max-height: 350px;
    }
}