.statistics-wrap {
    padding: 18px 0 4px;
}

.summary-statistic-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0 10px;
    margin-bottom: 0;
}

.summary-statistic-list li {
    width: 30%;
    text-align: center;
    padding: 10px 5px 6px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #ffffff;
    -webkit-box-shadow: 3px 3px 9px 0 rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 3px 3px 9px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 3px 3px 9px 0 rgba(0, 0, 0, 0.06);
}

.summary-statistic-list li .value {
    font-size: 15px;
    line-height: 1.35em;
    font-weight: 700;
}

.summary-statistic-list li .label {
    font-size: 11px;
    line-height: 1.35em;
    margin: 0;
}

@media (min-width: 768px) {
    .statistics-wrap {
        padding: 15px 0;
    }

    .summary-statistic-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin-bottom: 0;
    }

    .summary-statistic-list li {
        width: 15%;
        text-align: center;
        padding: 10px 5px 14px;
        margin-bottom: 0;
    }
    .summary-statistic-list li .value {
        font-size: 20px;
    }

    .summary-statistic-list li .label {
        font-size: 12px;
    }
}

@media (min-width: 1024px) {
    .statistics-wrap {
        padding: 30px 0;
    }

    .summary-statistic-list li .value {
        font-size: 24px;
    }

    .summary-statistic-list li .label {
        font-size: 14px;
    }
}
