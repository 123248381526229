.user-timeline-posts {
    margin-top: 29px;
}

.user-timeline-posts .item-loader {
    height: 100px;
}

.user-timeline {
    justify-content: center;
}

.user-timeline.card-list {
    padding: 0;
}

.user-timeline .card-timeline-holder {
    margin-bottom: 15px;
}


@media (min-width: 768px) {
    .user-timeline .card-timeline-holder {
        margin-bottom: 0;
    }

    .user-timeline-posts {
        margin-top: 0;
    }
}
