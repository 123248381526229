.card-media-slider-wrap {
    position: relative;
    width: 100%;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
}

.card-media-slider-wrap:after {
    content: "";
    padding-top: calc(100% + 1px);
    display: block;
}

.card-media-slider-wrap .card-media-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 2px;
}

.card-media-slider *:not(video, .magic-dots, .slick-arrow, .card-description),
.card-media-slider *:not(video, .magic-dots, .slick-arrow, .card-description) {
    height: 100%;
}

.card-media-slider .slick-initialized .slick-slide {
    position: relative;
}

.card-media-slider .card-image img {
    max-width: 100%;
    width: 100%;
    display: inline-block !important;
}

.card-media-slider .card-image.video {
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: #D8D8D8 url('../../../assets/no-video-cover.svg') center / 150px no-repeat;
}

.card-media-slider .card-image.video .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    min-height: 40px;
    background-color: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    height: auto !important;
    font-size: 18px;
    color: #676767;
    cursor: pointer;
    opacity: .8;
    transition: all ease-out 0.25s;
}

.card-media-slider .card-image.video .play-button:hover {
    opacity: 1;
}

.card-media-slider .card-image .card-description-holder {
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: 3px;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-media-slider .card-image .card-description-holder .card-description  {
    display: inline-block;
    color: white;
    text-align: center;
    font-weight: 700;
    background-color: rgba(0,0,0, .5);
    padding: 2px 10px;
    border-radius: 4px;
    font-size: 13px;
    line-height: 1.38em;
 }

.card-media-slider .ant-carousel .slick-dots-bottom {
    bottom: -25px;
    z-index: 2;
}

.card-media-slider .ant-carousel .slick-dots li {
    z-index: 2;
    margin: 0 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7px !important;
}

.card-media-slider .slick-dots li button:before{
    display: none;
}

.card-media-slider .slick-dots li.small button{
    transform: scale(0.7);
}

.card-media-slider .ant-carousel .slick-dots{
    height: 8px;
}

.card-media-slider .ant-carousel .slick-dots li button {
    opacity: 1;
}

.card-media-slider .slick-arrow:before {
    display: none;
}

.card-media-slider .ant-carousel .slick-arrow:focus,
.card-media-slider .ant-carousel .slick-arrow {
    width: 30px;
    height: 30px;
    z-index: 4;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 18px;
    border-radius: 50%;
    opacity: 0.6 !important;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
}

.card-media-slider .slick-dots li button {
    width: 7px !important;
    height: 7px;
    border-radius: 50%;
}

.card-media-slider .ant-carousel .slick-arrow:hover {
    opacity: 1 !important;
    background-color: rgba(0, 0, 0, 0.3);
}

.card-media-slider .slick-arrow.slick-disabled {
    display: none !important;
}

.card-media-slider .slick-arrow.slick-prev {
    left: 11px;
}

.card-media-slider .slick-arrow.slick-next {
    right: 11px;
}

.original-image-download,
.view-original-image {
    position: absolute;
    z-index: 10;
    right: 9px;
    bottom: 9px;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.original-image-wrap.loading img {
    display: none;
    touch-action: pinch-zoom;
}

.original-image-wrap .item-loader {
    width: 200px;
    height: 200px;
    background-color: #fff;
}

.original-image-download {
    right: 14px;
}

.original-image-download,
.view-original-image button {
    cursor: pointer;
    font-size: 18px;
    padding: 6px;
    border-radius: 4px;
    line-height: 1em;
    background-color: rgba(255, 255, 255, 0.75);
    border: none;
    outline: none;
}

.original-image-download {
    display: block;
}

.original-image-wrap {
    position: relative;
}

.original-image-modal .ant-modal {
    width: auto !important;
    max-width: 100vw;
}

.original-image-modal .ant-modal-body {
    padding: 40px 10px;
}

.original-image-modal .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
}

.original-image-modal .ant-modal-close {
    color: #fff;
}

.original-image-modal .ant-modal img {
    max-width: 100%;
    max-height: 88vh;
}

.original-image-modal .ant-modal-close {
    top: 12px;
    right: 10px;
}

.original-image-modal .ant-modal-close-x {
    font-size: 18px;
    width: 18px;
    height: 18px;
}

@media (min-width: 768px) {

    .original-image-modal .ant-modal-body {
        padding: 40px;
    }

    .original-image-download,
    .view-original-image {
        right: 14px;
        bottom: 14px;
    }

    .card-media-slider .slick-arrow.slick-prev {
        left: 15px;
    }

    .card-media-slider .slick-arrow.slick-next {
        right: 15px;
    }

    .original-image-download,
    .view-original-image button {
        font-size: 22px;
    }

    .original-image-modal .ant-modal-close {
        top: 16px;
        right: 16px;
    }

    .card-media-slider .card-image .card-description-holder .card-description {
        padding: 6px 15px;
        border-radius: 6px;
     }

     .card-media-slider .card-image.video {
        background-size: 285px;
    }

    .card-media-slider .card-image.video .play-button {
        min-width: 60px;
        min-height: 60px;
        font-size: 26px;
    }
}
