.header-actions {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 24px;
    align-items: center;
}

.header-actions .label {
    font-size: 14px;
    line-height: 1.33em;
    margin-left: 4px !important;
}

.header-actions button,
.header-actions a {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.header-actions li + li {
    margin-left: 30px;
}

.header-actions .header-action-create-post {
    min-width: 145px;
    padding: 8px 15px;
    border-radius: 20px;
}

.header-actions .header-action-create-post .anticon {
    font-size: 14px !important;
}

.header-actions .anticon:not(.ant-scroll-number-custom-component) {
    font-size: 20px;
}

.header-actions button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    outline: none;
}

@media (min-width: 1024px) {

    .header-actions .label {
        font-size: 17px;
        margin-left: 8px !important;
    }

    .header-actions .anticon:not(.ant-scroll-number-custom-component) {
        font-size: 24px;
    }
}
