.post-create-form .form-actions {
    display: flex;
    justify-content: center;
}

.post-create-form .holder-items {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 251px;
    margin: 0 auto;
}

.post-create-form .holder-items .selection-text {
    font-size: 12px;
    padding-bottom: 80px;
}

.post-create-form .upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.post-create-form .center-upload .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;
}

.post-create-form .upload-rules {
    text-align: center;
    font-size: 11px;
    line-height: 1.5em;
}

.post-create-form .ant-upload-select-picture-card:before,
.post-create-form .ant-upload-list-picture-card .ant-upload-list-item:before {
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    display: block;
    content: "";
}

.post-create-form .center-upload .ant-upload-select-picture-card,
.post-create-form .center-upload .ant-upload-list-picture-card .ant-upload-list-item {
    width: 96px;
}

.post-create-form .ant-upload-select-picture-card .ant-upload,
.post-create-form .ant-upload-list-item-info {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
    left: 0;
}

.post-create-form .ant-upload-select-picture-card {
    display: block;
}

.post-create-form .ant-upload-select-picture-card .ant-upload {
    display: flex;
    justify-content: center;
    align-items: center;
}

.post-create-form .ant-upload-list-item-thumbnail {
    opacity: 1;
}

.post-create-form .holder-items .ant-upload-list-item-thumbnail {
    background: url('../../../../assets/no-video-cover.svg') center / 60px no-repeat;
}

.post-create-form .holder-items .ant-upload-list-item-thumbnail svg {
    display: none;
}

.post-create-form .holder-items .ant-upload-list-item-name {
    display: none !important;
}

.post-create-form .ant-form-item {
    margin-bottom: 18px;
}

.post-create-form .center-upload .ant-upload-select-picture-card,
.post-create-form .center-upload .ant-upload-list-picture-card .ant-upload-list-item {
    background-color: #EDEDED;
}

@media (max-width: 767px) {

    .post-create-form button.rounded-button {
        margin-top: 12px;
        font-size: 12px;
        min-width: 100px;
    }
}

@media (min-width: 768px) {

    .post-create-form .upload-rules {
        font-size: 13px;
    }

    .post-create-form .center-upload .ant-upload-select-picture-card,
    .post-create-form .center-upload .ant-upload-list-picture-card .ant-upload-list-item {
        width: 190px;
    }

    .post-create .ant-form-item {
        margin-bottom: 24px;
    }

    .post-create-form .holder-items {
       max-width: 465px;
    }

    .post-create-form .holder-items .col {
        padding: 0 10px;
      }

      .post-create-form .holder-items .selection-text {
        font-size: 14px;
    }

    .post-create-form .holder-items .ant-upload-list-item-thumbnail {
        background-size: 100px;
    }
}

