.users-grid {
    margin-top: 20px;
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(auto, auto);
}

.user-grid-item{
    border-width: 1px;
    border-style: solid;
    padding: 14px;
    border-radius: 4px;
    position: relative;
    min-height: 130px;
}

.user-grid-item .user-info{
    margin-bottom: 50px;

}

.user-grid-item .card-follow{
    position: absolute;
    bottom: 15px;
    right: 15px;
    margin-top: 5px;
}

.user-grid-item .user-contacts{
    margin-bottom: 8px;
}

.user-grid-item .company-department{
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1em;
}

.user-grid-item .job-description{
    font-size: 11px;
    font-weight: bold;
    line-height: 1.6em;
}

.user-grid-item .working-on{
    font-size: 12px;
}


@media (max-width: 1580px) {

    .users-grid{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 767px) {

    .users-grid{
        margin: 15px;
        grid-template-columns: repeat(1, 1fr);
    }
}


/*&-six-col{*/
/*     @extend .grid;*/
/*     grid-template-columns: repeat(6, 1fr);*/
/*     grid-auto-rows: minmax(auto, auto);*/

/* }*/

/*&-five-col{*/
/*     @extend .grid;*/
/*     grid-template-columns: repeat(5, 1fr);*/
/*     grid-auto-rows: minmax(auto, auto);*/

/* }*/

/*&-four-col{*/
/*     @extend .grid;*/
/*     grid-template-columns: repeat(4, 1fr);*/
/*     grid-auto-rows: minmax(auto, auto);*/
/* }*/

/*&-three-col{*/
/*     @extend .grid;*/
/*     */
/* }*/

/*&-two-col{*/
/*     @extend .grid;*/
/*     grid-template-columns: repeat(2, 1fr);*/
/*     grid-auto-rows: minmax(auto, auto);*/
/* }*/

/*&-col{*/
/*     @extend .grid;*/
/*     grid-template-columns: repeat(1, 1fr);*/
/*     grid-auto-rows: minmax(auto, auto);*/
/* }*/
/*}*/