.card-dashboard-holder{
    align-items: stretch;
    margin-bottom: 15px;
}

.card-post{
    background-color: #FFFFFF;
    -webkit-box-shadow: 3px 4px 10px 0 rgba(0,0,0,0.12);
    -moz-box-shadow: 3px 4px 10px 0 rgba(0,0,0,0.12);
    box-shadow: 3px 4px 10px 0 rgba(0,0,0,0.12);
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
    height: 100%;
    position: relative;
    border: 1px solid #EDEDED;
}

.card-post .badge{
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 2;
    font-size: 20px;
    color: #FFF;
    padding: 6px 8px 4px;
    line-height: 1em;
    border-radius: 0 0 10px 0;
}

.card-post.news .card-media-slider-wrap{
    border: 3px solid #000;
}

.card-post.news .card-media-slider{
    padding: 3px;
}


.card-post .card-title{
    font: 700 14px/1.35em 'Open Sans', sans-serif;
    margin-bottom: 0;
}

.card-post .card-text{
    font: 14px/1.4em 'Open Sans', sans-serif;
    color: inherit;
}

.card-post .card-text .show-more{
    border: none;
    background: none;
    padding: 0;
    margin-left: 5px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.card-post .card-text div{
    font-size: 13px;
    white-space: pre-line;
    overflow-wrap: break-word;
}

.card-post .card-comments .show-more{
    font: 700 12px/1.3em 'Open Sans', sans-serif;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    outline: none;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.card-post .card-comments:not(.no-comments){
    border-top: 1px solid #EDEDED;
    padding-top: 8px;
    margin: 12px 18px 0 20px;
}

.card-post .card-footer-wrap,
.card-post .card-text,
.card-post .card-attachments{
    padding: 0 11px 0 11px;
}

.card-post .card-attachments{
    margin: 10px 0;
}

.card-post .card-attachments .ant-btn > .anticon + span{
    margin-left: 6px;
}

.card-post .card-attachments button{
    border-color: transparent;
    height: 30px;
    font-size: 13px;
    padding: 0 14px;
    border-radius: 16px;
    -webkit-transition: all ease-out 0.25s;
    -moz-transition: all ease-out 0.25s;
    -o-transition: all ease-out 0.25s;
    transition: all ease-out 0.25s;
}

.card-post .card-footer{
    width: 100%;
    margin-bottom: 15px;
}

.card-post .card-footer-wrap{
    min-height: 32px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.card-post .post-created{
    margin-top: 10px;
    font-size: 11px;
    line-height: 1.4em;
}

.card-post .card-add-info > span + span{
    padding-left: 8px;
    margin-left: 8px;
    position: relative;
}

.card-post .card-add-info > span + span:before{
    position: absolute;
    left: 0;
    content: "";
    width: 1px;
    height: 1em;
    background-color: #333;
    top: 3px;
}

.card-post .card-add-info button{
    height: 26px;
}

.card-post .view-amount,
.card-post .like-amount button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    font-size: 13px;
    box-shadow: none;
}

.card-post .like-amount button{
    cursor: pointer;
}

.card-post .card-add-info{
    padding: 0 11px 5px 11px;
}



@media (max-width: 767px) {
    .card-post .ant-avatar.ant-avatar-circle.ant-avatar-image{
        width: 40px !important;
        height: 40px!important;
    }
}

@media (min-width: 768px) {
    .card-post{
        border-radius: 8px;
    }

    .card-post .badge{
        top: 4px;
        left: 4px;
        font-size: 30px;
        padding: 9px 13px 6px;
        border-radius: 0 0 10px 0;
    }

    .card-post .card-footer-wrap,
    .card-post .card-text,
    .card-post .card-attachments{
        padding: 0 18px 0 20px;
    }

    .card-dashboard-holder{
        margin-bottom: 0;
    }

    .card-post.news .card-media-slider-wrap{
        border-width: 3px;

    }

    .card-post.news .card-media-slider{
        padding: 3px;
    }

    .card-post .view-amount,
    .card-post .like-amount button {
        font-size: 14px;
        font-weight: bold;
    }

    .card-post .post-created{
        font-size: 12px;
    }

    .card-post .card-title{
        font: 700 17px/1.35em 'Open Sans', sans-serif;
        margin-bottom: 5px;
    }

    .card-post .card-text{
        font: 14px/1.6em 'Open Sans', sans-serif;
    }

    .card-post .card-text div{
        font-size: 14px;
    }

    .card-post .card-attachments button{
       font-size: 14px;
    }

    .card-post .card-attachments{
        margin-top: 16px;
        margin-bottom: 20px;
    }

    .card-post .card-add-info{
        padding: 0 18px 10px 20px;
    }

    .card-post .card-comments .show-more{
        font: 700 13px/1.3em 'Open Sans', sans-serif;
    }
}
