.post-update-media .ant-upload-list-item-thumbnail{
    opacity: 1;
}

.post-update-media .ant-upload-select-picture-card:before,
.post-update-media .ant-upload-list-picture-card .ant-upload-list-item:before{
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    display: block;
    content: "";
}

.post-update-media .ant-upload-picture-card-wrapper{
    width: calc(100% + 10px);
    margin: 0 -4px;
}

.post-update-media .ant-upload-select-picture-card .ant-upload,
.post-update-media .ant-upload-list-item-info{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.post-update-media .ant-upload-select-picture-card{
    position: relative;
    display: block;
    background-color: #EDEDED;
}

.post-update-media .ant-upload-select-picture-card .ant-upload{
    display: flex;
    justify-content: center;
    align-items: center;
}

.post-update-media .upload-label{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 11px;
    font-weight: 700;
}

.post-update-media .upload-label .icon{
    font-size: 22px;
    margin-right: 5px;
    color: #EB008B;
}

.post-update-media .upload-label .row{
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50%;
}

.post-update-media .upload-label .row:last-child{
    top: 50%;
    bottom: 0;
    border-top: 1px solid #DDDDDD;
}

.post-update-media .ant-upload-list-picture-card-container.video-item:before {
    content: '';
    background: rgba(255,255,255, .8) url('../../../../assets/video-fill.svg') center/16px no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    border-radius: 3px;
    overflow: hidden;
}


@media (min-width: 768px) {
    .post-update-media .ant-upload-picture-card-wrapper{
        width: calc(100% + 14px);
        margin: 0 -7px;
    }

    .post-update-media .upload-label{
        font-size: 14px;
    }

    .post-update-media .upload-label .icon{
        font-size: 30px;
    }

    .post-update-media .ant-upload-list-picture-card-container.video-item:before {
        background-size: 24px;
        width: 30px;
        height: 30px;
        top: 10px;
        right: 10px;
        border-radius: 3px;
        overflow: hidden;
    }
}